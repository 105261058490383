import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { FaqDetail } from "../../../components/faq/detail"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `FAQ`, path: `question` },
  { text: `パートナーシップについて`, path: `question/partnership` },
]

const faqNavItems: { text: string; path: string }[] = [
  { text: `パートナーシップについて`, path: `#faq_partnership` },
]

const FaqTitle: string = "パートナーシップに関するFAQ";

export default function FaqPartnershipPage() {
  const { site: { siteMetadata: { faqDetail } } } = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        faqDetail {
          partnership {
            details {
              answer
              question
            }
            path
            text
          }
        }
      }
    }
  }
`);

  const [ faqDetails ] = faqDetail;

  return (
    <FaqDetail
      breadcrumbsData={breadcrumbsData}
      faqNavItems={faqNavItems}
      faqDetails={faqDetails.partnership}
      faqTitle={FaqTitle}
    />
  )
}